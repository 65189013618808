var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wh-container",attrs:{"id":"tiss-manage-guides"}},[_c('div',{staticClass:"nav"},[_c('Breadcrumbs',{attrs:{"options":[
        {
          label: 'Faturamento TISS',
          onClick: () => this.$router.push('/faturamento-tiss/dashboard')
        },
        {
          label: 'Lotes enviados',
          onClick: () => this.$router.push('/faturamento-tiss/lotes-enviados')
        },
        {
          label: 'Gerenciar guias',
          onClick: () =>
            this.$router.push({
              name: 'tiss-manage-guides',
              params: { batchId: _vm.$route.params.batchId }
            })
        },
        {
          label: 'Lote faturado'
        }
      ]}}),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.canUpdateTissGuides},on:{"click":_vm.bulkUpdateTissGuides}},[_vm._v(" Salvar ")])],1),_c('b-container',{staticClass:"guides-content",attrs:{"fluid":""}},[_c('GuidesHeader',{attrs:{"type":"batch","id":_vm.$route.params.batchId,"loading":_vm.loading,"disabledStatus":_vm.selectedTissGuides.length === 0},on:{"update":_vm.updateStatuses,"reload":_vm.getGuides}}),_c('GuidesFilter',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('GuidesTable',{attrs:{"tissGuides":_vm.tissGuides,"selectedTissGuides":_vm.selectedTissGuides,"handleSelectRow":_vm.handleSelectRow,"selectAll":_vm.selectAll,"unSelectAll":_vm.unSelectAll}}),_c('ManageGuidesSidebar',{attrs:{"canUpdateTissGuides":_vm.canUpdateTissGuides},on:{"update":_vm.updateGuides,"save":_vm.bulkUpdateTissGuides}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }